import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    component: () =>
      import("@/views/index/index.vue"),
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import("@/views/index/success.vue"),
  },
  {
    path: "/pc",
    name: "pc",
    component: () =>
      import("@/views/index/indexPc.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
